<template>
    <v-card class="elevation-0">
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
          <v-text-field
              v-on:keyup.enter="searchOnEnter"
              v-on:blur="searchOnEnter"
              append-icon="mdi-magnify"
              hint="Kode, Deskripsi (press ENTER to search)"
              label="Search"
          ></v-text-field>
          <v-btn
              v-if="false"
              icon fab
              color="blue"
              small
              class="ml-2"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
      </v-card-title>

      <v-data-table
          v-model="selectedItems"
          :single-select="!multiSelect"
          :show-select="multiSelect"
          :headers="headers"
          :items="fTemplateRegsFiltered"
          :page.sync="currentPage"
          :items-per-page="pageSize"
          hide-default-footer
          class="elevation-0"
          @page-count="totalTablePages =totalPaginationPages "
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <v-switch
                v-model="multiSelect"
                :label="multiSelect?'Multi Select':'Single Select'"
                x-small
                disabled
            ></v-switch>
            <v-btn
                fab
                dark
                color="red accent-4"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="deleteDialogMultiShow"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
  
            <v-spacer></v-spacer>
  
            <v-btn
                fab
                dark
                color="green"
                x-small
                @click="showDialogNew"
                :disabled="multiSelect"
                class="ml-1"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </template>
  
        <template v-slot:[`item.number`]="{ index }">
          {{ (index + ((currentPage-1)*pageSize) +1 )}}
        </template>
  
        <template v-slot:[`item.description`]="{ item }">
          <div :class="item.ftemplateRegGroupBean >0? 'font-weight-light':'font-weight-reguler' ">
            {{ truncateDescription(item.description) }}
          </div>
          <div v-if="item.ftemplateRegGroupBean >0" class="font-weight-bold">
            {{ truncateDescription(lookupFTemplateRegGroup(item.ftemplateRegGroupBean).description) }}
          </div>
        </template>
  
        <template v-slot:[`item.fdivisionBean`]="{ item }">
            <div  class="text-caption small">
              {{ lookupFDivision(item.fdivisionBean).description }}
            </div>
            <div>
              <v-chip
                  :color="getColorStatusActive(item.statusActive)"
                  dark
                  x-small
              >
                {{ item.statusActive==true?'Aktif': '-' }}
              </v-chip>
            </div>
        </template>
        <template v-slot:[`item.avatarImage`]="{ item}">
            <v-img
                :lazy-src="lookupImageUrlLazy(item)"
                :src="lookupImageUrl(item)"
                alt="avatar"
                width="70px"
                height="70px"
                class="ma-2 rounded"
            >
            </v-img>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
              @click="showDialogEdit(item)"
              icon
              :disabled="multiSelect"
          >
            <v-icon
                small
                color="warning"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
  
          <v-btn
              @click="deleteDialogShow(item)"
              icon
              :disabled="multiSelect"
          >
            <v-icon
                small
                color="red accent-4"
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
  
      </v-data-table>
  
      <v-container>
        <v-row justify="end" align="center">
          <v-col
          cols="4"
          md="2"
          sm="2"
          >
            <v-select
                v-model="pageSize"
                :items="pageSizes"
                label="Items per page"
            ></v-select>
          </v-col>
          <v-col
              cols="10"
              md="9"
              sm="8"
              align="right"
          >
            <v-pagination
                v-model="currentPage"
                :length="totalPaginationPages"
                total-visible="100"
                circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>
  
      <DeleteConfirmDialog
          ref="refDeleteConfirmDialog"
          @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
          @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
      ></DeleteConfirmDialog>
  
      <FTemplateRegDialog
            :formMode.sync="formMode"
            :itemsFDivision = "itemsFDivision"
            ref="refFormDialog"
            @eventFromFormDialogNew="saveDataNew"
            @eventFromFormDialogEdit="saveDataEdit"
      ></FTemplateRegDialog>
  
      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
  
    </v-card>
  </template>
  
  <script>
  import FTemplateRegService from '@/services/apiservices/f-template-reg-service';
  import FDivisionService from "@/services/apiservices/f-division-service";
  import FJenisDokumenService from "@/services/apiservices/f-jenis-dokumen-service";
  import DeleteConfirmDialog from "@/components/utils/DeleteConfirmDialog";
  import FTemplateRegDialog from "./FTemplateRegDialog"; 
  import FormMode from "@/models/form-mode";
  import FTemplateReg from '@/models/f-template-reg'
  import FileService from "@/services/apiservices/file-service";
  import FTemplateRegGroupService from "@/services/apiservices/f-template-reg-group-service";
  
  export default {
    components: {
      FTemplateRegDialog,
      DeleteConfirmDialog
    },
    data () {
      return {
        title: 'TEMPLATE LAYANAN',
        snackbar: false,
        snackBarMesage: '',
  
        multiSelect: false,
        selectedItems: [],
  
        currentPage: 1,
        totalTablePages: 1,
        totalPaginationPages: 1,
        pageSize: 10,
        pageSizes: [10, 25, 50, 150, 500, 1500],
        search: '',
        headers: [
        {
            text: 'No',
            value: 'number',
            width: '8%',
            sortable: false
          },
          {
            text: '',
            align: 'center',
            sortable: false,
            value: 'avatarImage',
            width: '16%'
          },
          { text: 'Deskripsi', value: 'description', width:"40%" },
          { text: 'Division', value: 'fdivisionBean', width:"10%" },
          { text: 'Actions', value: 'actions', sortable: false, width:"20%", align: "center" },
        ],
        // formMode: '',
        itemSelectedIndex: -1,
        itemSelected: '',
        fTemplateRegs:[
          new FTemplateReg(0, '','')
        ], 
      }
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (value) this.fetchFTemplateReg()
        }
      },
      pageSize: {
        handler: function () {
          const refreshData = (this.currentPage==1)
          this.currentPage =1
          if (refreshData) {
            // console.log("Change PageSize " + value)
            this.fetchFTemplateReg()
          }
          // console.log("page size = " + value + " >> " + this.totalPages)
        }
      },
    },
    computed: {
      currentUser(){
        return this.$store.state.auth.user;
      },
      fTemplateRegsFiltered(){
        return this.fTemplateRegs
      },
      formMode: {
          get() {
            return this.$store.state.FTemplateRegModule.formMode
          },
          set (value){
            this.$store.dispatch('FTemplateRegModule/updateFormMode', value)
          }
        },
      itemsFDivision: {
        get(){
          return this.$store.state.FTemplateRegModule.itemsFDivision
        },
        set (value){
          this.$store.dispatch('FTemplateRegModule/updateItemsFDivision', value)
        }
      },
      itemsFJenisDokumen: {
        get(){
          return this.$store.state.FTemplateRegModule.itemsFJenisDokumen
        },
        set (value){
          this.$store.dispatch('FTemplateRegModule/updateItemsFJenisDokumen', value)
        }
      },
      itemsFTemplateRegGroup: {
        get() {
          return this.$store.state.FTemplateRegModule.itemsFTemplateRegGroup;
        },
        set(value) {
          this.$store.dispatch("FTemplateRegModule/updateItemsFTemplateRegGroup", value);
        },
      },

    },
    methods: {
      searchOnEnter(event){
        if (this.search !== event.target.value) { //Krusial untuk search
          // console.log(`${event.target.value} vs ${this.search}`)
          this.currentPage = 1
          this.search = event.target.value
          this.fetchFTemplateReg()
        }
      },
      fetchParent(){
        if (this.currentUser.organizationLevel === "DIV") {
          FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
              response=>{
                this.itemsFDivision = [response.data]
                // console.log(response.data.items)
              },
              error=>{
                console.log(error.response)
              }
          )
  
        }else {
          FDivisionService.getAllFDivision().then(
              response => {
                // console.log(JSON.stringify(response.data))
                this.itemsFDivision = response.data
              },
              error => {
                console.log(error.response)
              }
          )
        }

        FJenisDokumenService.getAllFJenisDokumen().then(
            response => {
              this.itemsFJenisDokumen = response.data
            },
            error => {
              console.log(error.response)
            }
        )
        FTemplateRegGroupService.getAllFTemplateRegGroup().then(
            response => {
              JSON.stringify(response.data, null, 2)
              this.itemsFTemplateRegGroup = response.data
            },
            error => {
              console.log(error.response)
            }
        )

      },
      fetchFTemplateReg() {
          FTemplateRegService.getAllFTemplateRegContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
              response => {
                const { items, totalPages} = response.data
                this.fTemplateRegs = items
                this.totalPaginationPages = totalPages
                // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
              },
              error => {
                console.log(error.response)
              }
          )
      },
      linkNeated(link){
        return link.replace(/\s+/g, '-').toLocaleLowerCase()
      },
  
      showDialogNew() {
  
        this.itemSelectedIndex =-1
        const itemModified = Object.assign({}, '')
        // this.formDialogShow = true
        this.formMode = FormMode.NEW_FORM
        this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified)
      },
      saveDataNew(itemFromRest){
        this.itemSelected = itemFromRest
        this.closeDialog()

        this.fetchFTemplateReg()
      },
  
      showDialogEdit (item) {
        this.itemSelectedIndex = this.fTemplateRegsFiltered.indexOf(item)
        const itemModified = Object.assign({}, item)
        // this.formDialogShow = true
        this.formMode = FormMode.EDIT_FORM
        this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
      },
      saveDataEdit(item){
          this.itemSelected = item
          try {
            Object.assign(this.fTemplateRegs[this.itemSelectedIndex], this.itemSelected)
          }catch (e) {
              this.fetchFTemplateReg()
              e.toString()
          }
          this.closeDialog()
      },
  
      deleteDialogShow (item) {
        // console.log(item.username)
  
        this.itemSelectedIndex = this.fTemplateRegsFiltered.indexOf(item)
        this.itemSelected = Object.assign({}, item)
        this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex)
      },
      deleteDialogMultiShow(){
        if (this.multiSelect===true){
          this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
        }
      },
      deleteItemConfirmedSingleSelect(index){
        console.log("delete SingleItem: " + index)
  
        const deletedItem = this.fTemplateRegs[this.itemSelectedIndex]
        FTemplateRegService.deleteFTemplateReg(deletedItem.id).then(
          () => {
            // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
            this.fTemplateRegs.splice(this.itemSelectedIndex, 1)
            this.closeDialog()
          },
          error => {
            console.log(error)
            this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
            this.snackbar = true
            this.$refs.refDeleteConfirmDialog.setDialogState(false)
          }
        )
      },
      deleteItemConfirmedMultiSelect(items){
        console.log(items)
  
      },
  
      closeDialog () {
        this.formMode = ''
        this.$refs.refDeleteConfirmDialog.setDialogState(false)
        this.$refs.refFormDialog.setDialogState(false)
  
        this.$nextTick(() => {
          this.itemSelected = Object.assign({}, new FTemplateReg())
          this.itemSelectedIndex = -1
        })
      },
  
      getColorStatusActive (trueFalse) {
        if (trueFalse === true) return 'green'
        else if (trueFalse === false) return 'gray'
        else return 'gray'
      },
      lookupFDivision (fdivisionBean) {
        const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
        if (str.length>0){
          return str[0]
        }else {
          return '-'
        }
      },
      lookupFTemplateRegGroup(ftemplateRegGroupBean) {
        const str = this.itemsFTemplateRegGroup.filter((x) => x.id === ftemplateRegGroupBean);
        if (str.length > 0) {
          return str[0];
        } else {
          return "-";
        }
      },

      lookupImageUrl(item){
        if (item.avatarImage===undefined || item.avatarImage===""){
          return './assets/images/no_image_available.jpeg'
        }else {
          return FileService.image_url_low(item.avatarImage)
        }
      },
      lookupImageUrlLazy(item){
        if (item.avatarImage===undefined || item.avatarImage===""){
          return './assets/images/no_image_available.jpeg'
        }else {
          return FileService.image_url_verylow(item.avatarImage)
        }
      },
      truncateDescription(description) {
        // if (description.length > 60) {
        //   return description.substring(0, 59) + "...";
        // } else {
        //   return description;
        // }
          return description;
      }
    },
    mounted() {

      if (!this.currentUser) {
        this.$router.push('/login')
      }else {
        this.fetchParent()
        this.fetchFTemplateReg()
  
      }

    }
  
  }
  </script>
  
  <style scoped>
  
  </style>