<template>
  <v-card class="elevation-0">
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="fTemplateRegDokumens"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages = totalPaginationPages"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
            v-model="multiSelect"
            :label="multiSelect ? 'Multi Select' : 'Single Select'"
            x-small
          ></v-switch>
          <v-btn
            fab
            dark
            color="red accent-4"
            x-small
            :disabled="!multiSelect"
            class="mr-2"
            @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            fab
            dark
            color="green"
            x-small
            @click="showDialogNew"
            class="ml-1"
            v-if="
              !multiSelect &&
              itemModified.kode1 !== '' &&
              (itemModified.fdivisionBean !== undefined) !== '' &&
              itemModified.description !== '' &&
              itemModified.openFrom !== undefined &&
              itemModified.openTo !== undefined
            "
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

<!--      <template v-slot:[`item.number`]="{ index }">-->
<!--        {{ index + (currentPage - 1) * pageSize + 1 }}-->
<!--      </template>-->
      <template v-slot:[`item.kode1`]="{ item }">
        {{ lookupFJenisDokumen(item.fjenisDokumenBean).kode1 }}
      </template>
      <template v-slot:[`item.fjenisDokumenBean`]="{ item }">
        <div>{{ lookupFJenisDokumen(item.fjenisDokumenBean).description }}</div>
      </template>
      <template v-slot:[`item.mandatory`]="{ item }">
        <v-icon small color="red" v-if="item.mandatory === true"
          >mdi-check-circle</v-icon
        >
      </template>
      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip :color="getColorStatusActive(item.statusActive)" dark x-small>
          {{ item.statusActive === true ? "Aktif" : "Non Aktif" }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="showDialogEdit(item)" :disabled="multiSelect" icon>
          <v-icon small color="warning"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn :disabled="multiSelect" @click="deleteDialogShow(item)" icon>
          <v-icon small color="red accent-4"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col cols="4" md="2" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per page"
          ></v-select>
        </v-col>
        <v-col cols="10" md="9" sm="8" align="right">
          <v-pagination
            v-model="currentPage"
            :length="totalPaginationPages"
            total-visible="8"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteConfirmDialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <FTemplateRegDokumenDialog
      ref="refFormDialog"
      :newFTemplateRegIdFiltered="newFTemplateRegIdFiltered"
      @eventFromFormDialogNew="saveDataNew"
      @eventFromFormDialogEdit="saveDataEdit"
    ></FTemplateRegDokumenDialog>
  </v-card>
</template>

<script>
import FTemplateRegDokumenService from "@/services/apiservices/f-template-reg-dokumen-service";
import FTemplateRegDokumenDialog from "./FTemplateRegDokumenDialog";
import DeleteConfirmDialog from "@/components/utils/DeleteConfirmDialog";
import FormMode from "@/models/form-mode";

export default {
  props: {
    newFTemplateRegId: Number,
  },
  components: {
    DeleteConfirmDialog,
    FTemplateRegDokumenDialog,
  },
  data() {
    return {
      title: "DOKUMEN PERSYARATAN",
      multiSelect: false,
      selectedItems: [],
      itemFTemplateReg: "",

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 99,
      pageSizes: [10, 25, 50, 150, 500, 1500],
      // formModeFTemplateRegDokumen: '',
      headers: [
        {
          text: "No",
          value: "nomorUrut",
          width: "8%",
          sortable: true,
        },
        {
          text: "Kode",
          value: "kode1",
          width: "12%",
          sortable: false,
        },
        { text: "Deskripsi", value: "fjenisDokumenBean", width: "45%" },
        { text: "Wajib", value: "mandatory", width: "8%" },
        { text: "Status", value: "statusActive", width: "8%" },
        { text: "Actions", value: "actions", sortable: false, width: "14%" },
      ],
      itemSelectedIndex: -1,
      itemSelected: "",
      fTemplateRegDokumens: [],
    };
  },
  computed: {
    newFTemplateRegIdFiltered() {
      return this.newFTemplateRegId;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    itemModified: {
      get() {
        return this.$store.state.FTemplateRegModule.itemModified;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemModified", value);
      },
    },
    itemDefault: {
      get() {
        return this.$store.state.FTemplateRegModule.itemDefault;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemDefault", value);
      },
    },
    itemsFJenisDokumen: {
      get() {
        return this.$store.state.FTemplateRegModule.itemsFJenisDokumen;
      },
      set(value) {
        this.$store.dispatch(
          "FTemplateRegModule/updateitemsFJenisDokumen",
          value
        );
      },
    },
    formModeFTemplateRegDokumen: {
      get() {
        return this.$store.state.FTemplateRegModule.formModeFTemplateRegDokumen;
      },
      set(value) {
        this.$store.dispatch(
          "FTemplateRegModule/updateFormModeFTemplateRegDokumen",
          value
        );
      },
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FTemplateRegModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemsFDivision", value);
      },
    },
  },
  watch: {

    itemsFJenisDokumen: {
      handler: function (value) {
        if (value) {
          this.runExtendedFilter();
          // console.log('itemsFJenisDokumen')
        }
      },
    },
    itemFTemplateReg: {
      handler: function (value) {
        if (value){
          if (value.id>0 && this.itemsFJenisDokumen.length > 0){
            this.runExtendedFilter();
            // console.log('itemFTemplateReg')
          }
        }
      },
    },

  },
  methods: {
    fetchFTemplateRegDokumenByFTemplateReg(item) {
      this.itemFTemplateReg = item;
      this.runExtendedFilter();
    },
    fetchFTemplateRegDokumen() {
      this.runExtendedFilter();
    },
    runExtendedFilter() {
      if (this.newFTemplateRegIdFiltered > 0 && this.itemsFJenisDokumen.length > 0) {
        FTemplateRegDokumenService.getAllFTemplateRegDokumenByParent(this.newFTemplateRegIdFiltered).then(
            response => {
              // console.log(response.data)
              this.fTemplateRegDokumens = response.data;
            }
        )
      }
    },
    lookupFJenisDokumen(fjenisDokumenBean) {
      const str = this.itemsFJenisDokumen.filter(
        (x) => x.id === fjenisDokumenBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    showDialogNew() {
      this.itemSelectedIndex = -1;
      const itemModified = Object.assign({}, "");
      this.formModeFTemplateRegDokumen = FormMode.NEW_FORM;
      this.$refs.refFormDialog.showDialog(
        this.itemSelectedIndex,
        itemModified,
        this.itemFTemplateReg
      );
      if (this.formModeFTemplateRegDokumen === FormMode.NEW_FORM) {
        this.$emit("saveCreateOnly");
      }
    },
    saveDataNew(itemFromRest) {
      this.itemSelected = itemFromRest;
      /**
       * Setiap data baru harus terlihat
       */
      this.fetchFTemplateRegDokumen();
      this.$refs.refFormDialog.dialogShow = false;
    },

    showDialogEdit(item) {
      this.itemSelectedIndex = this.fTemplateRegDokumens.indexOf(item);
      const itemModified = Object.assign({}, item);
      this.formModeFTemplateRegDokumen = FormMode.EDIT_FORM;
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified);
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      Object.assign(
        this.fTemplateRegDokumens[this.itemSelectedIndex],
        this.itemSelected
      );
      this.$refs.refFormDialog.dialogShow = false;
    },

    deleteDialogShow(item) {
      this.itemSelectedIndex = this.fTemplateRegDokumens.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex);
    },
    deleteDialogMultiShow() {
      if (this.multiSelect === true) {
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(
          this.selectedItems,
          `${this.selectedItems.length} items selected`
        );
      }
    },
    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.fTemplateRegDokumens[this.itemSelectedIndex];
      FTemplateRegDokumenService.deleteFTemplateRegDokumen(deletedItem.id).then(
        () => {
          this.fTemplateRegDokumens.splice(this.itemSelectedIndex, 1);
          this.$refs.refDeleteConfirmDialog.dialogShow = false;
        },
        (error) => {
          console.log(error);
          this.snackBarMesage = "gagal hapus (digunakan oleh data anakk)";
          this.snackbar = true;
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      if (items.length > -1) {
        let itemIds = [];
        for (let i = 0; i < items.length; i++) {
          itemIds.push(items[i].id);
        }

        FTemplateRegDokumenService.deleteAllFTemplateRegDokumen(itemIds).then(
          () => {
            this.selectedItems = [];
            this.$refs.refDeleteConfirmDialog.dialogShow = false;

            if (this.showFilter) {
              this.runExtendedFilter();
            } else {
              this.fetchFTemplateRegDokumen();
            }
          },
          (error) => {
            console.log("error " + error);
          }
        );
      }
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },
  },
};
</script>