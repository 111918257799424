<template>
    <v-card class="mt-2 ml-2 mr-2">
        <FTemplateRegTable class="pl-2 pr-2" ref="refTemplateRegTable"/>
    </v-card>
  </template>
  
  <script>
  import FTemplateRegTable from "@/components/admin_setup/template_reg/FTemplateRegTable";
  
  export default {
    name: "TemplateReg",
    components: {
      FTemplateRegTable
    },
    data() {
      return {
        tabTitle: 'TemplateReg'
      }
    },
    computed: {
    },
    methods: {
      tabTemplateRegClick(){
        this.$refs.refTemplateRegTable.fetchParent()
      },
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>