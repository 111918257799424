<template>
    <div>
      <v-dialog
        v-model="dialogShow"
        :max-width="formDialogOptions.width"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeForm"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :hide-overlay="$vuetify.breakpoint.smAndDown"
      >
  
        <v-card>
          <v-form  v-model="valid" ref="form">
  
            <v-toolbar
                dark
                color="brown"
                class="color-gradient-1"
                dense
            >
              <v-btn
                  icon
                  dark
                  @click="closeForm"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
  
              <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
              <span v-if="formModeFTemplateRegDokumen === 'EDIT_FORM' ">EDIT </span>
              <span class="font-weight-light ml-1 mr-1">ITEM</span>
              <span v-if="formModeFTemplateRegDokumen === 'NEW_FORM' "> BARU</span>

              <span 
                v-show="isItemModified"
                class="ml-2 mr-2 font-weight-medium grey--text" 
                >
                 |
              </span>

              <v-chip
              v-show="isItemModified"
                  class="ma-2"
                  color="warning"
                  outlined
                  x-small
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                modified
              </v-chip>
  
              <v-spacer></v-spacer>
              <v-toolbar-items >

                <v-btn
                    @click="save"
                    dark
                    text
                    :disabled="!valid || isItemModified===false"
                    class="hidden-md-and-up"
                >
                  Simpan
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
  
            <v-card-text>
              <v-container>
                <v-row>
                    <v-col
                        v-if="false"
                        cols="12"
                        sm="6"
                        md="2"
                    >
                        <v-text-field
                            v-model="itemDetilModified.kode1"
                            label="Kode1"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="2"
                    >
                        <v-text-field
                            v-model="itemDetilModified.nomorUrut"
                            label="Urutan"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col
                      cols="12"
                      sm="8"
                      md="8"
                  >
                    <v-text-field
                        v-model="itemDetilModified.description"
                        label="Deskripsi Detil Dokumen"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col
                        v-if="false"
                        cols="12"
                        sm="6"
                        md="4"
                    >
                        <v-menu
                            v-model="menuDate1"
                            :close-on-content-click="false"
                            max-width="290"
                        >
                        <template v-slot:activator="{ on, attrs } ">
                            <v-text-field
                                :value="computedDateFormattedDatefns(itemDetilModified.tanggalFile)"
                                prepend-inner-icon="mdi-calendar"
                                clearable
                                label="Tanggal File"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="itemDetilModified.tanggalFile = null"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="itemDetilModified.tanggalFile"
                            @change="menuDate1 = false"
                        ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                        <v-autocomplete
                        v-model="itemDetilModified.fjenisDokumenBean"
                        :items="itemsFJenisDokumen"
                        :rules="rulesNotEmtpy"
                        item-value="id"
                        item-text="description"
                        auto-select-first
                        small-chips
                        deletable-chips
                        color="blue-grey lighten-2"
                        label="Jenis Dokumen"
                        :hint="`Jenis Dokumen : ${lookupFJenisDokumen(itemDetilModified.fjenisDokumenBean).description}`"
                        persistent-hint
                        single-line
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="3" sm="3">
                    <v-text-field
                        v-model="itemDetilModified.maxSize"
                        label="Max Size (KB)"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-checkbox
                        v-model="itemDetilModified.mandatory"
                        label="Wajib Diisi"
                        x-small
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-checkbox
                        v-model="itemDetilModified.hidden"
                        label="Sembunyikan"
                        x-small
                    ></v-checkbox>
                  </v-col>
                </v-row>
  
             </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  outlined
                  text
                  @click="closeForm"
                  class="hidden-sm-and-down"
              >
                Batal
              </v-btn>
              <v-btn
                  @click="save"
                  color="blue darken-1"
                  outlined
                  :disabled="!valid || isItemModified===false"
                  text
                  class="hidden-sm-and-down mr-5"
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
  
        </v-card>
  
  
        <CloseConfirmDialog
          ref="refCloseConfirmDialog"
          @eventFromCloseConfirm="passingEventFromCloseConfirm"
        ></CloseConfirmDialog>
        <v-snackbar
            v-model="snackbar"
        >
          {{ snackBarMesage }}
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
  
  
      </v-dialog>
    </div>
  
  </template>

<script>
import FileService from "@/services/apiservices/file-service";
import FTemplateRegDokumen from '@/models/f-template-reg-dokumen'
import FTemplateRegDokumenService from '@/services/apiservices/f-template-reg-dokumen-service'
import FormMode from "@/models/form-mode";
import { format, parseISO } from 'date-fns'
import CloseConfirmDialog from '@/components/utils/CloseConfirmDialog'

  export default {
    components: {
        CloseConfirmDialog
    },
    props: {
      newFTemplateRegIdFiltered : Number
    },
    data(){
          return{
              title: 'DOKUMEN PERSYARATAN',
              snackBarMesage: '',
              snackbar: false,
              selectedItemIndex: -1,
              imageOverlay: false,
              date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
              menuDate1: false,
              // itemDetilDefault: '',
              // itemDetilModified: new FTemplateRegDokumen(0, '', ''),

              dialogShow: false,
              formDialogOptions: {
                  title1: '',
                  title2: '',
                  message1: '',
                  message2: '',
                  errorMessage: '',
                  color: "grey lighten-3",
                  width: 1000,
                  zIndex: 200,
                  noconfirm: false,
              },


              // selectFDivision: {id: 0, kode1: '', description: '' },
              // itemsFDivision: [],

              valid: false,
              rulesLenght: [
                  v => !!v || ' Tidak Boleh Kosong',
                  v => v.length <= 255 || 'Maksimal 255 Karakter',
                  v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
              ],
              rulesNotEmtpy: [
                  v => !!v || 'Tidak boleh kosong'
              ],
              transparent: 'rgba(255, 255, 255, 0)',
          }
      },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      isItemModified() {
        const defaultItem = JSON.stringify(this.itemDetilDefault)
        const modifiedItem = JSON.stringify(this.itemDetilModified)
        return defaultItem !== modifiedItem
      },
      itemDetilModified: {
        get(){
          return this.$store.state.FTemplateRegModule.itemDetilModified
        },
        set(value){
          this.$store.dispatch('FTemplateRegModule/updateItemDetilModified', value)
        }
      },
      itemDetilDefault: {
        get(){
          return this.$store.state.FTemplateRegModule.itemDetilDefault
        },
        set(value){
          this.$store.dispatch('FTemplateRegModule/updateItemDetilDefault', value)
        }
      },
      formModeFTemplateRegDokumen: {
        get() {
          return this.$store.state.FTemplateRegModule.formModeFTemplateRegDokumen
        },
        set (value){
          this.$store.dispatch('FTemplateRegModule/updateFormModeFTemplateRegDokumen', value)
        }
      },
      itemsFDivision: {
        get(){
          return this.$store.state.FTemplateRegModule.itemsFDivision
        },
        set (value){
          this.$store.dispatch('FTemplateRegModule/updateItemsFDivision', value)
        }
      },
      itemsFJenisDokumen: {
        get(){
          return this.$store.state.FTemplateRegModule.itemsFJenisDokumen
        },
        set (value){
          this.$store.dispatch('FTemplateRegModule/updateitemsFJenisDokumen', value)
        }
      },

    },
    methods: {
      formatDate (date) {
          if (!date) return null

          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
      },
      computedDateFormattedDatefns (value) {
          return value ? format(parseISO(value), 'dd MMM yyyy') : ''
      },
      save(){
        if (this.isItemModified===false){
          this.dialogShow = false
          this.$emit('eventFromFormDialog1', this.itemDetilModified)
          return
        }
        if (this.$refs.form.validate()){
          if (this.formModeFTemplateRegDokumen===FormMode.EDIT_FORM) {
            FTemplateRegDokumenService.updateFTemplateRegDokumen(this.itemDetilModified).then(
                () => {
                  this.$emit('eventFromFormDialogEdit', this.itemDetilModified)
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }else {
            
            this.itemDetilModified.ftemplateRegBean = this.newFTemplateRegIdFiltered 
            // console.log(this.itemDetilModified)
            FTemplateRegDokumenService.createFTemplateRegDokumen(this.itemDetilModified).then(
                response =>{
                  this.$emit('eventFromFormDialogNew', response.data)
                  // console.log(response.data)
                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )

          }
        }
      },
      closeForm(){
          if (! this.isItemModified){
              this.dialogShow = false
              this.$emit('eventFromFormDialog1', this.itemDetilModified)
          }else {
              this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
          }
      },
      
      lookupImageUrl(item){
        // console.log(JSON.stringify(item))

        if (item.fileName===undefined || item.fileName===""){
          return './assets/images/no_image_available.jpeg'
        }else {
          return FileService.image_url_medium(item.fileName)
        }
      },
      lookupImageUrlLazy(item){
        if (item.fileName===undefined || item.fileName===""){
          return './assets/images/no_image_available.jpeg'
        }else {
          return FileService.image_url_verylow(item.fileName)
        }
      },
      lookupFJenisDokumen (fjenisDokumenBean) {
          const str = this.itemsFJenisDokumen.filter(x => x.id===fjenisDokumenBean)
          if (str.length>0){
            return str[0]
          }else {
            return '-'
          }
        },
      passingEventFromCloseConfirm(value){
           if (value==='OKE') this.dialogShow = false
      },
      showDialog(selectedIndex, item, itemFTemplateReg) {

          this.dialogShow = !this.dialogShow
          if (selectedIndex >-1) {
              this.selectedIndex = selectedIndex
              this.initializeEditMode(item)
          }else {
              this.itemDetilDefault =  new FTemplateRegDokumen(0, '', '')
              this.itemDetilDefault.ftemplateRegBean = itemFTemplateReg.id
              this.itemDetilModified =  new FTemplateRegDokumen(0, '', '')
              this.itemDetilModified.ftemplateRegBean = itemFTemplateReg.id
              this.selectedIndex = -1
          }
      },
      saveUpdateOnly(){
        FTemplateRegDokumenService.updateFTemplateRegDokumen(this.itemDetilModified).then(
            () => {
            },
            error => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message
            }
        )
      },
      setDialogState(value){
        this.dialogShow =value
      },
      initializeEditMode(item){
        this.formDialogOptions.errorMessage = ''

        FTemplateRegDokumenService.getFTemplateRegDokumenById(item.id).then(
            response =>{

              this.itemDetilDefault = Object.assign({}, response.data)
              this.itemDetilModified = response.data
            },
            error =>{
              console.log(error)
            }
        )
      },
      saveCreateOnly(){
        FTemplateRegDokumenService.createFTemplateRegDokumen(this.itemDetilModified).then(
            () =>{
            },
            error => {
              this.formDialogOptions.errorMessage = error.response.data.message
            }
        )
      },
    }
  }
</script>