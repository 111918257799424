export default class FTemplateRegDokumen {
    constructor(
      id,
      kode1,
      nomorUrut,
      description,
      ftemplateRegBean,
      // tanggalFile,

      fjenisDokumenBean,
      mandatory,
      maxSize,
      fileType,
      hidden,

    ) {
      this.id = id;
      this.kode1 = kode1;
      this.nomorUrut = nomorUrut;
      this.description = description;
      this.ftemplateRegBean = ftemplateRegBean;
      // this.tanggalFile = tanggalFile;
      this.fjenisDokumenBean = fjenisDokumenBean;
      this.mandatory = mandatory;
      this.maxSize = maxSize;
      this.fileType = fileType;
      this.hidden = hidden;
    }
  
  }
  