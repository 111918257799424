export default class FTemplateReg {
    constructor(
      id,
      kode1,
      description,
      statusActive,
      fdivisionBean,
      ftemplateRegGroupBean,
      avatarImage,
      showPeriodeBulan,
      showPeriodeTahun,
      showJenisProsedur,
      showJenisKenaikanPangkat,
      showJenisJabatan,
      showJenisKartu,
      openFrom,
      openTo,
      whatsAppAdmin

    ) {
      this.id = id;
      this.kode1 = kode1;
      this.description = description;
      this.statusActive = statusActive;
      this.fdivisionBean = fdivisionBean;
      this.ftemplateRegGroupBean = ftemplateRegGroupBean;
      this.avatarImage = avatarImage;
      this.showPeriodeTahun = showPeriodeTahun;
      this.showPeriodeBulan = showPeriodeBulan;
      this.showJenisProsedur = showJenisProsedur;
      this.showJenisKenaikanPangkat = showJenisKenaikanPangkat;
      this.showJenisJabatan = showJenisJabatan;
      this.showJenisKartu = showJenisKartu;
      this.openFrom = openFrom;
      this.openTo = openTo;
      this.whatsAppAdmin = whatsAppAdmin;
    }
  
  }
  